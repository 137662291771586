export default {
  props: {
    content: {
      type: [Object, Array],
      default: {},
    },
  },
  computed: {
    blocks() {
      if (Array.isArray(this.content)) {
        return this.content;
      }

      if (
        this.content &&
        typeof this.content === "object" &&
        Array.isArray(this.content.blocks)
      ) {
        return this.content.blocks;
      }

      return [];
    },
  },
  methods: {
    getBlockComponentName(block) {
      if (!block.type || typeof block.type !== "string") {
        return "UnknownBlock";
      }

      return utilities.ucFirst(block.type) + "Block";
    },
    getBlockUniqueId(block) {
      return (
        (block.id ? block.id : "") + "-block-" + utilities.getUniqueNumber()
      );
    },
  },
};
